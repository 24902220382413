<template>
  <v-container id="types" fluid tag="section" class="mt-10">
    <v-row>
      <v-col cols="12" md="8">
        <material-card color="primary" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h4">{{ getHeading }}</div>
            </div>
          </template>

          <v-card-text v-if="data_is_loaded" class="mt-5">
            <div
              class="
                list-config
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <div class="tableConfig d-inline-flex">
                <!-- Reload data !-->
                <div class="text-center">
                  <a href="#" @click="reloadData($event)">
                    <v-icon color="blue"> mdi-refresh </v-icon>
                  </a>
                </div>
              </div>

              <v-spacer />

              <div class="d-inline-flex">
                <div>
                  <base-button
                    :is_accent="true"
                    text="Add new"
                    :to="getNewUrl"
                  />
                </div>

                <v-toolbar
                  class="short-toolbar pt-1"
                  elevation="0"
                  hide-details
                  dense
                >
                  <v-text-field
                    v-model="search"
                    prepend-icon="mdi-magnify"
                  ></v-text-field>
                </v-toolbar>
              </div>
            </div>

            <v-expansion-panels ref="objects" class="pa-10" focusable>
              <v-expansion-panel
                v-for="(object, key) in filtredObjectsList"
                :key="key"
              >
                <v-expansion-panel-header>
                  {{ object.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <div class="mt-5 flex-row">
                      <span>
                        <router-link :to="getViewUrl(object.id)">
                          <v-icon color="blue"> mdi-magnify </v-icon>
                        </router-link>
                      </span>
                      <span v-if="canUserEdit(object)">
                        <router-link :to="getEditUrl(object.id)">
                          <v-icon color="blue"> mdi-pencil </v-icon> 
                        </router-link>
                      </span>
                      <span v-if="canUserEdit(object)">
                        <a href="#" @click="deleteType($event, object)">
                          <v-icon color="blue"> mdi-delete </v-icon>
                        </a>
                      </span>
                    </div>
                  </div>

                  <div class="sub-container" v-if="null != object.author">
                    <div class="flex-row">
                      <div class="column thin">Author:</div>
                      <div class="column wide">
                        {{
                          null != object.author ? object.author.username : ""
                        }}
                      </div>
                    </div>

                    <div class="flex-row">
                      <div class="column thin">Team:</div>
                      <div class="column wide">
                        {{ null != object.team ? object.team.name : "" }}
                      </div>
                    </div>
                  </div>

                  <div class="sub-container">
                    <div class="flex-row">
                      <div class="column thin">
                        Name of a unique identifier:
                      </div>
                      <div class="column wide">
                        {{ object.name_of_uid }}
                      </div>
                    </div>
                  </div>

                  <div class="sub-container" v-if="null != object.icon">
                    <div class="flex-row">
                      <div class="column thin">Icon:</div>
                      <div class="column wide">
                        <icon-image :image_src="object.icon" />
                      </div>
                    </div>
                  </div>

                  <div class="sub-container">
                    <documentation-input
                      :disabled="true"
                      :doc_list_original="object.basic_docs_list"
                    />
                  </div>

                  <div
                    class="sub-container"
                    v-if="
                      null != object.custom_fields_devices &&
                        '[]' != object.custom_fields_devices
                    "
                  >
                    <div class="flex-row">
                      <div class="column thin">Custom:</div>
                      <div class="column wide">
                        <custom-fields-view
                          :custom="object.custom_fields_devices"
                        />
                      </div>
                    </div>
                  </div>

                  <template v-if="null != object">
                    <div class="sub-container">
                      <firmwares-of-type-table
                        :firmwares="object['firmwares']"
                      />
                    </div>

                    <div class="sub-container">
                      <list-of-groups-of-type
                        :devices_groups="
                          null == object.devices_groups
                            ? []
                            : object.devices_groups
                        "
                      />

                      <v-divider v-if="null != object.devices"></v-divider>

                      <list-of-devices
                        :devices="null == object.devices ? [] : object.devices"
                      />
                    </div>

                    <div class="subgroup sub-container">
                      <div class="flex-row">
                        <div class="column thin">Notes:</div>
                        <div class="column wide">
                          <textarea
                            class="my-0"
                            :rows="
                              null == object['notes'] ||
                              0 == object['notes'].length
                                ? 1
                                : 5
                            "
                            type="text"
                            id="notes"
                            :value="object['notes']"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getViewUrl,
  getEditUrl,
  getNewUrl,
  getCategoryLabel,
  isDevice,
  isGroup,
  isFirmware,
  getTypeCategory,
  getGroupCategory,
  OBJECT_CATEGORIES
} from "../utils/RoutesUtils";
import { deleteType, getObjectsList, getTeamsListWhereUserIsAdminCall } from "../utils/ServerUtils.js";
import IconImage from "../components/app/IconImage.vue";
import CustomFieldsView from "../components/app/CustomFieldsView.vue";
import BaseButton from "../components/app/BaseButton.vue";
import { mapActions, mapGetters } from "vuex";
import ListOfGroupsOfType from "../layouts/default/widgets/ListOfGroupsOfType.vue";
import FirmwaresOfTypeTable from "../layouts/default/widgets/FirmwaresOfTypeTable.vue";
import ListOfDevices from "../layouts/default/widgets/ListOfDevices.vue";
import DocumentationInput from "../layouts/default/widgets/DocumentationInput.vue";

export default {
  components: {
    IconImage,
    CustomFieldsView,
    BaseButton,
    ListOfGroupsOfType,
    FirmwaresOfTypeTable,
    ListOfDevices,
    DocumentationInput
  },
  name: "TypesList",

  props: {
    user: Object
  },

  data() {
    return {
      object_category: OBJECT_CATEGORIES.TYPE,
      objects_list: [],
      table_columns: [],
      selected_type_id: null,
      selected_group_id: null,
      data_is_loaded: false,
      
      teams_where_user_is_admin: [],

      search: ""
    };
  },

  watch: {
    search: function() {
      // trigger filtredObjectsList change
      const objects_list = this.objects_list;
      this.objects_list = objects_list;
    }
  },

  mounted: async function() {
    this.loadData();
    this.getTeamsWhereUserIsAdmin();
  },

  computed: {
    ...mapGetters(["filter_selected_team_id"]),

    filtredObjectsList: function() {
      var filtred_objects_list = [];
      for (let i = 0; i < this.objects_list.length; i++) {
        const object_i = this.objects_list[i];
        if (
          !this.isFilteredByTeam(object_i) &&
          !this.isFilteredBySearch(object_i)
        ) {
          filtred_objects_list.push(object_i);
        }
      }
      return filtred_objects_list;
    },

    getHeading: function() {
      const wait_server_indicator = this.data_is_loaded
        ? ""
        : " [waiting for server response]";

      const objects_num_string =
        null == this.filter_selected_team_id
          ? this.objects_list.length
          : this.filtredObjectsList.length + "/" + this.objects_list.length;
      return (
        getCategoryLabel(this.object_category, true) +
        " (" +
        objects_num_string +
        ")" +
        wait_server_indicator
      );
    },

    hasTargetFirmware: function() {
      return isDevice(this.object_category) || isGroup(this.object_category);
    },

    hasType: function() {
      return (
        isDevice(this.object_category) ||
        isGroup(this.object_category) ||
        isFirmware(this.object_category)
      );
    },

    hasDate: function() {
      return isDevice(this.object_category) || isFirmware(this.object_category);
    },

    isDevice: function() {
      return isDevice(this.object_category);
    },

    isFirmware: function() {
      return isFirmware(this.object_category);
    },

    getNewUrl: function() {
      return getNewUrl(this.object_category);
    },

    getTypeCategory() {
      return getTypeCategory();
    },

    getGroupCategory() {
      return getGroupCategory();
    }
  },

  methods: {
    ...mapActions(["updateLastUpdateTime"]),
    
    async getTeamsWhereUserIsAdmin() {
      const objects_list = await getTeamsListWhereUserIsAdminCall();
      if (null != objects_list) this.teams_where_user_is_admin = objects_list;
    },

    loadData: async function() {
      this.data_is_loaded = false;
      const objects_list = await getObjectsList(this.object_category);
      if (null != objects_list) this.objects_list = objects_list;
      this.data_is_loaded = true;

      this.updateLastUpdateTime();
    },

    reloadData: function(event) {
      event.preventDefault();
      this.loadData();
    },

    typeIsSelected: function(object) {
      if (null != object) {
        this.selected_type_id = object.id;
      } else {
        this.selected_type_id = null;
      }
    },

    groupIsSelected: function(object) {
      if (null != object) {
        this.selected_group_id = object.id;
      } else {
        this.selected_group_id = null;
      }
    },

    getViewUrl: function(object_id) {
      return getViewUrl(object_id, this.object_category);
    },

    getEditUrl: function(object_id) {
      return getEditUrl(object_id, this.object_category);
    },

    deleteType: async function(e, object) {
      e.preventDefault();
      await deleteType(object);
      this.loadData();
    },

    isFilteredBySearch(object) {
      return (
        this.search.length > 0 &&
        !object.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },

    isFilteredByTeam(object) {
      if (this.isTeam) return false;

      return (
        null != this.filter_selected_team_id &&
        object.team_id != this.filter_selected_team_id
      );
    },
    
    canUserEdit(object) {
      for (const admin_team of this.teams_where_user_is_admin) {
        if (admin_team.id == object.team_id) return true;
      }
      return false;
    },
  }
};
</script>
